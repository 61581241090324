import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";
import { MOCK_LAUNCH_DARKLY_DATA } from "~/mocks/mockData/launchDarklyMockData";

type Organization = {
  id: string;
  enterpriseVendorId?: string;
};

type IdentifyUserParams = {
  key: string;
  internal: boolean;
};

type IdentifyParams = {
  userId: string;
  employeeId?: string;
  organizations?: Organization[];
  vendorId?: string;
  ppcCampaignId?: string;
};

export type FeatureFlag = keyof typeof MOCK_LAUNCH_DARKLY_DATA;

export const useFeatureFlags = () => {
  const flags = useFlags();
  const ldClient = useLDClient();

  const isFeatureEnabled = useCallback(
    (featureName: FeatureFlag) => {
      return !!flags[featureName];
    },
    [flags],
  );

  const identifyAnonymous = useCallback(() => {
    if (!ldClient) {
      throw new Error("ldClient not initialized");
    }

    ldClient.identify({
      kind: "user",
      anonymous: true,
    });
  }, [ldClient]);

  const identifyUser = useCallback(
    ({ ...params }: IdentifyUserParams) => {
      if (!ldClient) {
        throw new Error("ldClient not initialized");
      }

      // If we don't have a user ID we identify them as anonymous instead
      if (!params.key) {
        return identifyAnonymous();
      }

      ldClient.identify({
        kind: "user",
        ...params,
      });
    },
    [ldClient, identifyAnonymous],
  );

  const identify = useCallback(
    ({ userId, organizations = [], employeeId, ...params }: IdentifyParams) => {
      if (!ldClient) {
        throw new Error("ldClient not initialized");
      }

      // If we don't have a user ID we identify them as anonymous instead
      if (!userId) {
        return identifyAnonymous();
      }

      const user = {
        internal: Boolean(employeeId),
        key: userId,
        ...params,
      };

      if (!organizations.length) {
        return identifyUser(user);
      }
      organizations.forEach((organization) => {
        ldClient.identify({
          kind: "multi",
          user,
          organization: {
            key: organization.id,
            enterpriseVendorId: organization.enterpriseVendorId,
          },
        });
      });
    },
    [ldClient, identifyAnonymous, identifyUser],
  );

  return {
    features: flags,
    isFeatureEnabled,
    identify,
    identifyUser,
    identifyAnonymous,
  };
};
